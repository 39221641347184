import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environment';

if (environment.environment === 'production') {
  enableProdMode();
}

void platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true,
});
